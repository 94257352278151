export const apiUrlProxy = process.env.NEXT_ARCHIVE_API_PROXY;
export const apiUrlMain = process.env.NEXT_ARCHIVE_API_MAIN;
export const DEMO_WALLET = '0x006cc1b89e9b68e08eec14a514d17b47b363acce';
export const ETHEREUM_MAINNET_ID = '1';
export const AUTH_TOKEN_KEY = 'authToken';
export const protocolManifestMap = {
  uniswap: 'uniswap',
  clipper: 'clipper',
  balancer: 'balancer',
  ichi: 'ichi',
  aave: 'aave',
  aave_v2: 'aave_v2',
  compound: 'compound',
  spark: 'spark',
};
